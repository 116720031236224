import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientData } from '../pagesCustom/invoices/invoices-models';

const apiURLs = {
  root: environment.restApiUrl,
  dashboard: '/client/',
  feedback: '/client/feedback/',
  paymetRec: '/client/payroll/ytd/'
}

@Injectable({
  providedIn: 'root'
})
export class DashboardPageService {

  public clientData =  new BehaviorSubject<ClientData | null>(null);

  constructor(private http: HttpClient,) { }

  getDashboardData(ytd?: number): Observable<any> {
    let queryParameters = new HttpParams();
    if (ytd) {
      queryParameters = queryParameters.append('ytd', ytd);
    }
    return this.http.get(apiURLs.root + apiURLs.dashboard, { params: queryParameters });
  }

  sendFeedback(data: {message: string}): Observable<any> {
    return this.http.post(apiURLs.root + apiURLs.feedback, data);
  }

  getPaymentRec(dateFrom?: string, dateTo?: string) {
    let queryParameters = new HttpParams();
    if (dateFrom && dateTo) {
      // date in format YYYY-MM-DD
      queryParameters = queryParameters.append('from_date', dateFrom);
      queryParameters = queryParameters.append('to_date', dateTo);
    }
    return this.http.get(apiURLs.root + apiURLs.paymetRec, { params: queryParameters });
  }
}
